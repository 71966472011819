import { configureStore } from "@reduxjs/toolkit";
import PropertySlice from "./slices/PropertySlice";
import ProfileSlice from "./slices/ProfileSlice";
import CurrentPropertySlice from "./slices/CurrentPropertySlice";
import EntryCodeSlice from "./slices/EntryCodeSlice";
import AllPropertySlice from "./slices/AllPropertySlice";
import AllTenantSlice from "./slices/AllTenantSlice";

// Create the Redux store
export const Store = configureStore({
  reducer: {
    Properties: PropertySlice,
    Profile: ProfileSlice,
    CurrentProperty: CurrentPropertySlice,
    EntryCode: EntryCodeSlice,
    AllProperties: AllPropertySlice,
    AllTenants: AllTenantSlice,
  },
});

// Optional: Infer the `AppDispatch` type from the store itself
export type AppDispatch = typeof Store.dispatch;
export type RootState = ReturnType<typeof Store.getState>;
