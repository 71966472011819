import React from "react";
import {
  FaArrowLeft,
  FaSearch,
  FaTools,
  FaBell,
  FaEdit,
  FaTrash,
} from "react-icons/fa";
import useScrollToTop from "../../../components/useScrollToTop";
import { Link, useSearchParams } from "react-router-dom";
import user1 from "./img/bruce-mars.jpg";
import user2 from "./img/ivana-square.jpg";
import user3 from "./img/kal-visuals-square.jpg";
import BottomNavbar from "../../../components/BottomNavbar";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";

let residents = [
  {
    id: 1,
    img: user1,
    firstname: "Joshua",
    email: "johndoe@gmail.com",
    role: "owner",
    status: "available",
    registered: "2024/6/8",
  },
  {
    id: 2,
    img: user2,
    firstname: "Stephanie",
    email: "stephanie@gmail.com",
    role: "tenant",
    status: "outdoor",
    registered: "2023/10/11",
  },
  {
    id: 3,
    img: user3,
    firstname: "Gloria",
    email: "gloria@gmail.com",
    role: "owner",
    status: "available",
    registered: "2021/12/21",
  },
];

const AdminPropertyDetails = () => {
  useScrollToTop();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const State = useSelector((state: RootState) => state);
  const { allProperties: properties } = State.AllProperties;
  const { allTenants: tenants } = State.AllTenants;

  const currentProperty = properties.find((item) => item._id === id);
  const currentTenants = tenants.filter((tenant) =>
    currentProperty?.tenants.includes(tenant._id)
  );
  return (
    <div className="h-screen w-screen bg-gray-800">
      {/* Sidebar */}

      {/* Main Content */}
      <div className="lg:w-[74vw] lg:ml-[15vw] h-full pt-4 lg:pt-6 max-[450px]:px-2">
        <div className="flex justify-between items-center">
          <h2 className="text-[0.8rem] lg:text-[1.7rem] ml-7 lg:ml-0 font-bold text-white">
            <Link to={"/admin-management"}>
              <button className="flex gap-2 items-center">
                <FaArrowLeft /> Back
              </button>
            </Link>
          </h2>

          <span className="flex gap-4 lg:gap-8">
            <form id="search-form">
              <FaSearch className="absolute mt-[0.74rem] ml-2 text-gray-400" />
              <input
                type="text"
                placeholder="Find Resident"
                className="py-[0.45rem] lg:py-2 md:py-2 max-[450px]:w-[9rem] pl-8 rounded-lg shadow-md shadow-gray-300"
              />
            </form>
            <span className="flex text-white gap-3 items-center">
              <FaTools className="cursor-pointer hover:text-blue-400" />
              <FaBell className="cursor-pointer hover:text-blue-400" />
            </span>
          </span>
        </div>

        <div className="bg-gray-50 max-[450px]:overflow-x-scroll py-3 lg:py-5 max-[450px]:pl-2 max-[450px]:pr-4 lg:px-5 rounded-xl shadow shadow-gray-500 mt-[12%] lg:mt-[5%] z-20 absolute w-[95.5vw] lg:w-[74vw]">
          <table className="w-[95vw] lg:w-full">
            <thead>
              <tr className="border-b border-gray-300">
                <th className="py-2 text-[0.9rem] lg:py-2 md:py-2 text-left pl-4 pr-5">
                  Resident
                </th>
                <th className="py-2 text-[0.9rem] lg:py-2 md:py-2 text-left pl-4 pr-5">
                  Firstname
                </th>
                <th className="py-2 text-[0.9rem] lg:py-2 md:py-2 text-center pl-4 pr-5">
                  Lastname
                </th>
                <th className="py-2 text-[0.9rem] lg:py-2 md:py-2 text-center pl-4 pr-5">
                  Amount Paid
                </th>
                <th className="py-2 text-[0.9rem] lg:py-2 md:py-2 text-center pl-4 pr-5">
                  Outstanding
                </th>
                <th className="py-2 text-[0.9rem] lg:py-2 md:py-2 text-left pl-4 pr-5">
                  Payment Date
                </th>
                <th className="py-2 text-[0.9rem] lg:py-2 md:py-2 text-left pl-4 pr-5">
                  Expiry Date
                </th>
                <th className="py-2 text-[0.9rem] lg:py-2 md:py-2 text-left pl-4 pr-5">
                  Phone
                </th>
              </tr>
            </thead>
            <tbody>
              {currentTenants.map((user) => (
                <tr key={user._id} className="border-b border-gray-300">
                  <td className="px-2 lg:px-4 py-2 md:py-2">
                    <div className="my-1 lg:my-2 block lg:flex gap-3">
                      <span>
                        <img
                          src={user.image}
                          alt=""
                          className="w-[2.4rem] lg:w-[4rem] rounded-lg"
                        />
                      </span>
                    </div>
                  </td>
                  <td className="px-2 lg:px-4 py-2 md:py-2">
                    <div className="my-2">
                      <h2 className="text-sm mt-1">{user.firstname}</h2>
                    </div>
                  </td>
                  <td className="px-2 lg:px-4 py-2 md:py-2">
                    <div className="my-2">
                      <h2 className="text-sm">{user.lastname}</h2>
                    </div>
                  </td>
                  <td className="px-2 lg:px-4 py-2 md:py-2">
                    <div className="my-2">
                      <h2 className="text-sm">{user.amountPaid}</h2>
                    </div>
                  </td>
                  <td className="px-2 lg:px-4 py-2 md:py-2">
                    <div className="my-2">
                      <h2 className="text-sm">{user.outstanding}</h2>
                    </div>
                  </td>
                  <td className="px-2 lg:px-4 py-2 md:py-2">
                    <div className="my-2">
                      <h2 className="text-sm">
                        {user.paymentDate?.split("T")[0]}
                      </h2>
                    </div>
                  </td>
                  <td className="px-2 lg:px-4 py-2 md:py-2">
                    <div className="my-2">
                      <h2 className="text-sm">
                        {user.expiryDate?.split("T")[0]}
                      </h2>
                    </div>
                  </td>
                  <td className="px-2 lg:px-4 py-2 md:py-2">
                    <div className="my-2">
                      <h2 className="text-sm">{user.phone}</h2>
                    </div>
                  </td>
                  <td className="px-2 lg:px-4 py-2 md:py-2">
                    <div className="my-0 lg:my-2 grid grid-cols-1 md:grid-cols-2 gap-3 lg:flex lg:gap-1 mt-[35%]">
                      <FaEdit className="cursor-pointer hover:text-blue-600" />
                      <FaTrash className="text-red-600 cursor-pointer hover:text-red-700" />
                    </div>
                  </td>
                  {/* <td className="px-2 lg:px-4 py-2 md:py-2">
                    <div className="my-0 lg:my-2 mt-[35%] ml-2 md:ml-0">
                      <button className="bg-gray-600 text-white px-3 py-2 rounded-lg text-sm">
                        More
                      </button>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="bg-gray-100 h-[10vh] lg:h-[20vh] w-[100vw] mt-[40vh] px-2 rounded-lg">
          <p className="lg:ml-[24vw] lg:mt-[38vh]">
            {" "}
            &copy; 2024. Built by <b>Ebeano Tech</b>
          </p>
        </div>
      </div>

      <BottomNavbar current={"dashboard"} />
    </div>
  );
};

export default AdminPropertyDetails;
