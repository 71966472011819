import React, { useState } from "react";
import login from "../assets/img/pexels-vika-glitter-392079-1648776.jpg";
import { motion } from "framer-motion";
import cozyez from "../assets/img/dbestlogo.jpeg";
import desktopLogin from "../assets/img/pexels-fotoaibe-1643383.jpg";
import { Link, useNavigate } from "react-router-dom";
import { account, database } from "../appWrite"; // Importing the Appwrite client setup
import useScrollToTop from "../components/useScrollToTop";

interface FormData {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  password: string;
  gender: string;
  code: string;
  role: string;
}

const Signup: React.FC = () => {
  useScrollToTop();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    gender: "",
    code: "",
    role: "",
  });
  const [error, setError] = useState<string | null>(null); // State for error messages

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null); // Reset the error state

    try {
      // Fetch all codes from the codes collection in Appwrite
      const codes = await database.listDocuments(
        "67121ea200295b186e8d", // replace with your actual database ID
        "671220d7002b931e8daa" // replace with your actual codes collection ID
      );

      // Check if the provided code matches any of the stored codes
      const isAuthorized = codes.documents.some(
        (item) => item.code === formData.code
      );

      if (!isAuthorized) {
        setError("Invalid signup code.");
        return;
      }

      // Create the user in Appwrite
      const user = await account.create(
        "unique()", // unique ID for the user
        formData.email,
        formData.password,
        formData.firstname
      );

      // Check if a session already exists
      let session;
      try {
        session = await account.getSession("current"); // Try to get the current session
      } catch (err) {
        // No active session found, so we proceed to create a new one
        session = await account.createEmailPasswordSession(
          formData.email,
          formData.password
        );
      }

      // Store additional attributes in the database
      await database.createDocument(
        "67121ea200295b186e8d", // replace with your actual database ID
        "67121ec9000c0778952e", // replace with your actual users collection ID
        "unique()",
        {
          userId: user.$id,
          phone: formData.phone,
          gender: formData.gender,
          role: formData.role,
        }
      );

      setIsLoading(false);
      console.log(user);
      if (user.$id) {
        navigate("/signup-success");
      }
    } catch (error) {
      console.log(error);
      setError("An error occurred during signup.");
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 2,
      }}
      viewport={{
        amount: "all",
      }}
      className="relative"
    >
      <img
        src={login}
        className="absolute w-[100vw] h-[100vh] lg:hidden object-cover"
        alt=""
      />
      <img
        src={desktopLogin}
        className="absolute w-[100vw] h-[100vh] object-cover max-[450px]:hidden"
        alt=""
      />
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black opacity-60 z-10 h-[100vh]"></div>

      <div className="text-white relative z-20">
        <img
          src={cozyez}
          className="w-[4.6rem] absolute z-20 ml-[-2.4vw] lg:ml-0"
          alt=""
        />
        <Link to={"/login"}>
          <button className="ml-[80%] bg-blue-600 py-1 px-2 rounded absolute lg:ml-[84%] mt-[4vh]">
            Back
          </button>
        </Link>
        <h1 className="text-center text-[1.5rem] font-bold pt-[15vh] lg:pt-[3vh]">
          Sign up
        </h1>

        <div className="p-6 max-[450px]:mt-[0rem] md:mt-8 lg:mt-2 rounded-lg shadow-xl lg:ml-[5rem]">
          <form onSubmit={handleSubmit}>
            {error && <p className="text-red-500">{error}</p>}{" "}
            {/* Display error message if any */}
            <input
              type="text"
              name="firstname"
              id="firstname"
              placeholder="First name"
              value={formData.firstname}
              onChange={handleChange}
              className="border border-gray-100 bg-gray-900 placeholder:text-white py-[0.4rem] px-4 w-full rounded mb-3 md:mb-5 md:w-[60vw] md:ml-[18vw] lg:w-[40vw] lg:ml-[22vw]"
            />
            <input
              type="text"
              name="lastname"
              id="lastname"
              placeholder="Last name"
              value={formData.lastname}
              onChange={handleChange}
              className="border border-gray-100 bg-gray-900 placeholder:text-white py-[0.4rem] px-4 w-full rounded mb-3 md:mb-5 md:w-[60vw] md:ml-[18vw] lg:w-[40vw] lg:ml-[22vw]"
            />
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email address"
              value={formData.email}
              onChange={handleChange}
              className="border border-gray-100 bg-gray-900 placeholder:text-white py-[0.4rem] px-4 w-full rounded mb-3 md:mb-5 md:w-[60vw] md:ml-[18vw] lg:w-[40vw] lg:ml-[22vw]"
            />
            <input
              type="tel"
              name="phone"
              id="phone"
              placeholder="Phone number"
              value={formData.phone}
              onChange={handleChange}
              className="border border-gray-100 bg-gray-900 placeholder:text-white py-[0.4rem] px-4 w-full rounded mb-3 md:mb-5 md:w-[60vw] md:ml-[18vw] lg:w-[40vw] lg:ml-[22vw]"
            />
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="border border-gray-100 bg-gray-900 placeholder:text-white py-[0.4rem] px-4 w-full rounded mb-3 md:mb-5 md:w-[60vw] md:ml-[18vw] lg:w-[40vw] lg:ml-[22vw]"
            />
            <input
              type="text"
              name="code"
              id="code"
              placeholder="Admin Code"
              value={formData.code}
              onChange={handleChange}
              className="border border-gray-100 bg-gray-900 placeholder:text-white py-[0.4rem] px-4 w-full rounded mb-3 md:mb-5 md:w-[60vw] md:ml-[18vw] lg:w-[40vw] lg:ml-[22vw]"
            />
            <div className="flex gap-4 mb-3 md:ml-[18vw] lg:w-[40vw] lg:ml-[22vw]">
              <label htmlFor="role">Gender</label>
              <label className="flex items-center gap-1">
                <input
                  type="radio"
                  name="gender"
                  checked={formData.gender === "male"}
                  onChange={handleChange}
                  value="male"
                />
                Male
              </label>
              <label className="flex items-center gap-1">
                <input
                  type="radio"
                  name="gender"
                  checked={formData.gender === "female"}
                  onChange={handleChange}
                  value="female"
                />
                Female
              </label>
            </div>
            <div className="flex gap-4 mb-3 md:w-[60vw] md:ml-[18vw] text-black lg:w-[40vw] lg:ml-[22vw]">
              <label htmlFor="role" className="text-white">
                Role
              </label>
              <select
                name="role"
                value={formData.role}
                onChange={handleChange}
                className="flex items-center gap-1"
              >
                <option value="">Select Role</option>
                <option value="resident">Resident</option>
                <option value="staff">Staff</option>
                <option value="admin">Admin</option>
              </select>
            </div>
            <p className="mt-2 flex gap-2 md:w-[60vw] md:ml-[18vw] lg:w-[40vw] lg:ml-[22vw]">
              Already have an account?{" "}
              <Link
                className="font-semibold text-blue-600 bg-black bg-opacity-60 px-1 py-[0.1rem]"
                to="/login"
              >
                Log in
              </Link>
            </p>
            <button
              className={`w-full py-2 mt-5 bg-blue-600 text-white font-semibold transition rounded hover:bg-blue-800 md:w-[60vw] md:ml-[18vw] ${
                isLoading && "animate-pulse"
              } lg:w-[40vw] lg:ml-[22vw]`}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Submiting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default Signup;
