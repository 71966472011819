// src/redux/codeSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { database } from "../../appWrite";

// Define a type for the property
interface Property {
  // Define the properties that a property object should have
  _id: string;
  images: string[]; // Array of image URLs
  title: string;
  price: string;
  category: string;
  description: string;
  location: string;
  condition: string;
  features: string;
  bedrooms: string;
  bathrooms: string;
  tenants: string[];
}

// Define the initial state type
interface AllPropertyState {
  allProperties: Property[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

// Async thunk to fetch the properties from the server
export const fetchProperties = createAsyncThunk<Property[], void>(
  "properties/fetchProperties",
  async () => {
    const response = await database.listDocuments(
      "67121ea200295b186e8d", // Your database ID
      "67121ee20011e15470aa" // Your collection ID for properties
    );

    // Map the documents to the Property type
    const allProperties = response.documents.map((doc) => ({
      _id: doc.$id,
      images: doc.images, // Ensure that 'images' exists on your document model
      title: doc.title,
      price: doc.price,
      category: doc.category,
      description: doc.description,
      location: doc.location,
      condition: doc.condition,
      features: doc.features,
      bedrooms: doc.bedrooms,
      bathrooms: doc.bathrooms,
      tenants: doc.tenants,
    }));

    return allProperties;
  }
);

const initialState: AllPropertyState = {
  allProperties: [],
  status: "idle",
  error: null,
};

const allPropertySlice = createSlice({
  name: "properties",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProperties.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchProperties.fulfilled,
        (state, action: PayloadAction<Property[]>) => {
          state.status = "succeeded";
          state.allProperties = action.payload; // Corrected this line
        }
      )
      .addCase(fetchProperties.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export default allPropertySlice.reducer;
